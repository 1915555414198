<template>
    <div class="container mt-5">
      <h1>Register</h1>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="username">Username</label>
          <input type="text" v-model="username" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" v-model="email" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" v-model="password" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input type="text" v-model="firstName" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input type="text" v-model="lastName" class="form-control" required />
        </div>
        <button type="submit" class="btn btn-primary">Register</button>
      </form>
    </div>
  </template>
  
  <script>
  import authService from '../services/authService';
  
  export default {
    name: 'RegisterView',
    data() {
      return {
        username: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
      };
    },
    methods: {
      async register() {
        try {
          await authService.register({
            username: this.username,
            email: this.email,
            password: this.password,
            firstName: this.firstName,
            lastName: this.lastName,
          });
          this.$router.push('/login');
        } catch (error) {
          alert('Registration failed: ' + error.message);
        }
      },
    },
  };
  </script>
  