<template>
    <div class="container mt-5">
      <h2>User Form</h2>
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input type="text" v-model="user.username" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input type="email" v-model="user.email" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input type="password" v-model="user.password" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="firstName" class="form-label">First Name</label>
          <input type="text" v-model="user.firstName" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="lastName" class="form-label">Last Name</label>
          <input type="text" v-model="user.lastName" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="role" class="form-label">Role</label>
          <select v-model="user.role" class="form-select">
            <option value="customer">Customer</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
  import userService from '../../../services/userService';
  
  export default {
    name: 'UserUpate',
    data() {
      return {
        user: {
          username: '',
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          role: 'customer'
        }
      };
    },
    methods: {
      async submitForm() {
        try {
          if (!this.user.password) {
            delete this.user.password;
          }

          await userService.updateUser(this.user.id, this.user);
          this.$router.push('/admin/users');
        } catch (error) {
          alert('Failed to submit the form: ' + error.message);
        }
      }
    },
    async created() {
      const id = this.$route.params.id;
      const response = await userService.getUserById(id);
      this.user = response.data;
    }
  };
  </script>
  