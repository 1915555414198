<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-4" v-for="product in products" :key="product.id">
        <div class="card mb-4">
          <img :src="product.imageUrl" class="card-img-top" alt="Product Image" />
          <div class="card-body">
            <h5 class="card-title">{{ product.name }}</h5>
            <p class="card-text">{{ product.description }}</p>
            <p class="card-text">${{ product.price }}</p>
            <router-link :to="'/products/' + product.id" class="btn btn-primary">View Details</router-link>
            <button class="btn btn-secondary mt-2" @click="addToCart(product)">Add to Cart</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productService from '../services/productService';
import { useCartStore } from '../store/cart';

export default {
  name: 'ProductList',
  props: {
    filteredProducts: Array
  },
  data() {
    return {
      products: [],
    };
  },
  async created() {
    const data = await productService.getAllProducts();
    this.products = data.data.data
  },
  async mounted() {
    if (this.filteredProducts && this.filteredProducts.length) {
      this.products = this.filteredProducts
    } else {
      const data = await productService.getAllProducts();
      this.products = data.data.data
    }
  },
  methods: {
    addToCart(product) {
      const cartStore = useCartStore();
      cartStore.addToCart(product);
    },
  },
};
</script>
