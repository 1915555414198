<template>
    <div class="container mt-5">
      <h2>Search Results</h2>
      <div class="row">
        <div class="col-md-4" v-for="product in products" :key="product.id">
          <div class="card mb-4">
            <img :src="product.imageUrl" class="card-img-top" alt="Product Image" />
            <div class="card-body">
              <h5 class="card-title">{{ product.name }}</h5>
              <p class="card-text">{{ product.description }}</p>
              <p class="card-text">${{ product.price }}</p>
              <router-link :to="'/products/' + product.id" class="btn btn-primary">View Details</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import productService from '../services/productService';
  
  export default {
    name: 'SearchComponent',
    data() {
      return {
        products: [],
      };
    },
    async created() {
      const query = this.$route.query;
      this.products = await productService.searchProducts(query);
    },
  };
  </script>
  