<template>
    <div class="container mt-5">
    <h2>Categories</h2>
    <router-link to="/admin/category/add">
    <btn class="btn btn-success">Add</btn>
  </router-link>

    <table class="table">
      <thead class="table-dark">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Name</th>
          <th scope="col">Description</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
        <tbody>
          <tr v-for="(category, i) in this.categories" :key="i">
            <th scope="row">{{ category.id }}</th>
            <td>{{ category.name }}</td>
            <td>{{ category.description }}</td>
            <td>
              <div class="d-inline me-2"><i class="bi bi-pencil" @click="editCategory(category.id)"></i></div>
              <div class="d-inline ms-2"><i class="bi bi-trash" @click="deleteCategory(category.id)"></i></div>
            </td>
          </tr>
        </tbody>
    </table>

    </div>
</template>


<script>
import categoryService from '../../../services/categoryService';

  export default {
    name: 'CategoryList',
    data() {
      return {
        categories: []
      };
    },
    methods: {
      async deleteCategory(id) {
        await categoryService.deleteCategory(id)
        location.reload();
      },
      editCategory(id) {
        this.$router.push({ name: "CategoryUpdate", params: { id: id } });
      }
    },
    async created() {
      const response = await categoryService.getAllCategories();
      this.categories = response.data;
    }
  }
</script>