import axios from 'axios';

const API_URL = 'https://zavrsni-rad-backend.ludereterram.com/api/auth/';

const register = (user) => {
  return axios.post(`${API_URL}register`, user);
};

const login = (user) => {
  return axios.post(`${API_URL}login`, user)
    .then(response => {
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    })
    .catch(error => {
      console.log(error)
    });
};

const logout = () => {
  localStorage.removeItem('token');
};

export default {
  register,
  login,
  logout,
};
