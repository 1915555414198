<template>
    <div class="container mt-5">
      <h2>Category Products</h2>
      <ProductList :filteredProducts="products" />

    </div>
  </template>
  
  <script>
  import productService from '../services/productService';
  import ProductList from '../components/ProductList.vue';

  export default {
    name: 'CategoriesComponent',
    components: {
      ProductList,
    },
    data() {
      return {
        products: [],
      };
    },
    async created() {
      const categoryId = this.$route.params.id;
      const response = await productService.getByCategoryId(categoryId);
      this.products = response.data;
    },
  };
  </script>
  