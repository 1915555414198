<template>
    <div class="container mt-5">
      <h2>Checkout</h2>
      <form @submit.prevent="submitOrder">
        <div class="mb-3">
          <label for="address" class="form-label">Address</label>
          <input type="text" v-model="order.address" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="paymentMethod" class="form-label">Payment Method</label>
          <select v-model="order.paymentMethod" class="form-select" required>
            <option value="credit-card">Credit Card</option>
            <option value="paypal">PayPal</option>
          </select>
        </div>
        <button type="submit" class="btn btn-primary">Place Order</button>
      </form>
    </div>
  </template>
  
  <script>
  import orderService from '../services/orderService';
  
  export default {
    name: 'CheckoutComponent',
    data() {
      return {
        order: {
          address: '',
          paymentMethod: 'credit-card',
          items: []
        }
      };
    },
    methods: {
      async submitOrder() {
        try {
          this.order.items = JSON.parse(localStorage.getItem('cartItems')) || [];
          await orderService.createOrder(this.order);
          localStorage.removeItem('cartItems');
          this.$router.push('/');
        } catch (error) {
          alert('Failed to place the order: ' + error.message);
        }
      }
    }
  };
  </script>
  