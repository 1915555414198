<template>
    <div class="container mt-5">
      <h2>Product Form</h2>
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="name" class="form-label">Name</label>
          <input type="text" v-model="product.name" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="description" class="form-label">Description</label>
          <input type="text" v-model="product.description" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="price" class="form-label">Price</label>
          <input type="number" v-model="product.price" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="categoryId" class="form-label">Category</label>
          <input type="number" v-model="product.categoryId" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="stock" class="form-label">Stock</label>
          <input type="number" v-model="product.stock" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="imageUrl" class="form-label">Image URL</label>
          <input type="text" v-model="product.imageUrl" class="form-control" />
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
  import productService from '../../../services/productService';
  
  export default {
    name: 'ProductForm',
    data() {
      return {
        product: {
          name: '',
          description: '',
          price: 0,
          categoryId: 0,
          stock: 0,
          imageUrl: ''
        }
      };
    },
    methods: {
      async submitForm() {
        try {
          if (this.product.id) {
            await productService.updateProduct(this.product.id, this.product);
          } else {
            await productService.createProduct(this.product);
          }
          this.$router.push('/admin/products');
        } catch (error) {
          alert('Failed to submit the form: ' + error.message);
        }
      }
    },
    async created() {
      const id = this.$route.params.id;
      if (id) {
        this.product = await productService.getProductById(id);
      }
    }
  };
  </script>
  