<template>
    <div>
      <h1>Shopping Cart</h1>
      <Cart />
    </div>
  </template>
  
  <script>
  import Cart from '../components/Cart.vue';
  
  export default {
    name: 'CartView',
    components: {
      Cart,
    },
  };
  </script>
  