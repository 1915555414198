import axios from 'axios';

const API_URL = 'https://zavrsni-rad-backend.ludereterram.com/api/products/';

const getAllProducts = () => {
  return axios.get(API_URL);
};

const getProductById = (id) => {
  return axios.get(`${API_URL}${id}`);
};

const getByCategoryId = (id) => {
  return axios.get(`${API_URL}search?category=${id}`);
};

const createProduct = (product) => {
  return axios.post(API_URL, product, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const updateProduct = (id, product) => {
  return axios.put(`${API_URL}${id}`, product, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

const deleteProduct = (id) => {
  return axios.delete(`${API_URL}${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

export default {
  getAllProducts,
  getProductById,
  getByCategoryId,
  createProduct,
  updateProduct,
  deleteProduct,
};
