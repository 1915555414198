<template>
  <div class="container mt-5">
    <h2>Shopping Cart</h2>
    <div class="row mb-2 d-none d-md-flex">
      <div class="col-md-2"><strong>Image</strong></div>
      <div class="col-md-4"><strong>Product</strong></div>
      <div class="col-md-3"><strong>Quantity</strong></div>
      <div class="col-md-2"><strong>Total</strong></div>
      <div class="col-md-1"><strong>Actions</strong></div>
    </div>
    <div v-for="item in cartItems" :key="item.product.id" class="border-bottom my-2">
      <div class="row my-2">
        <div class="col-12 col-md-2 d-flex flex-column align-items-center">
          <img :src="item.product.imageUrl" alt="Product Image" class="img-thumbnail mb-2" style="width: 100%">
        </div>
        <div class="col-12 col-md-4 d-flex align-items-center">
          <h5 class="card-title mb-0">{{ item.product.name }}</h5>
        </div>
        <div class="col-12 col-md-3 d-flex align-items-center">
          <div class="input-group">
            <button
              class="btn btn-outline-secondary me-2"
              @click="decrementQuantity(item.product.id)"
              v-if="item.quantity > 1"
            >-</button>
            <input type="text" class="form-control me-2" :value="item.quantity" readonly style="width: 60px;">
            <button class="btn btn-outline-secondary" @click="incrementQuantity(item.product.id)">+</button>
          </div>
        </div>
        <div class="col-12 col-md-2 d-flex align-items-center">
          <p class="card-text mb-0">{{ formatPrice(item.product.price * item.quantity) }}</p>
        </div>
        <div class="col-12 col-md-1 d-flex align-items-center">
          <button class="btn btn-danger" @click="removeFromCart(item.product.id)">Remove</button>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <h3>Total: ${{ formatPrice(cartTotal) }}</h3>
      <router-link to="/checkout" class="btn btn-success">Proceed to Checkout</router-link>
    </div>
  </div>
</template>

<script>
import { useCartStore } from '../store/cart';
import { mapActions, mapState } from 'pinia';

export default {
  name: 'CartComponent',
  computed: {
    ...mapState(useCartStore, ['cartItems', 'cartTotal']),
  },
  methods: {
    ...mapActions(useCartStore, ['removeFromCart', 'incrementQuantity', 'decrementQuantity']),
    formatPrice(value) {
      if (typeof value === 'number') {
        return value.toFixed(2);
      }
      return parseFloat(value).toFixed(2);
    },
  },
};
</script>
