<template>
    <div class="container mt-5">
      <h1>Welcome to Our E-commerce Store</h1>
      <ProductList />
    </div>
  </template>
  
  <script>
  import ProductList from '../components/ProductList.vue';
  
  export default {
    name: 'HomeComponent',
    components: {
      ProductList,
    },
  };
  </script>
  