<template>
    <div class="container mt-5">
      <h2>Order Form</h2>
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="status" class="form-label">Status</label>
          <input type="text" v-model="order.status" class="form-control" required />
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
  import orderService from '../../services/orderService';
  
  export default {
    name: 'OrderForm',
    data() {
      return {
        order: {
          status: ''
        }
      };
    },
    methods: {
      async submitForm() {
        try {
          if (this.order.id) {
            await orderService.updateOrder(this.order.id, this.order);
          } else {
            await orderService.createOrder(this.order);
          }
          this.$router.push('/admin/orders');
        } catch (error) {
          alert('Failed to submit the form: ' + error.message);
        }
      }
    },
    async created() {
      const id = this.$route.params.id;
      if (id) {
        this.order = await orderService.getOrderById(id);
      }
    }
  };
  </script>
  