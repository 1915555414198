<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">E-Commerce</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li v-if="!isAuthenticated" class="nav-item">
            <a class="nav-link active"  href="/login" :to="{ name: 'Login' }">Login</a>
          </li>
          <li v-if="!isAuthenticated" class="nav-item">
            <a class="nav-link active" href="/register" aria-current="page" :to="{ name: 'Register' }">Register</a>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <a class="nav-link"  href="/cart">Cart</a>
          </li>
          <li v-if="isAuthenticated && isAuthorized" class="nav-item">
            <a class="nav-link"  href="/admin">Admin</a>
          </li>
          <li  v-if="isAuthenticated" class="nav-item">
            <a class="nav-link" href="#" @click="logoutUser">Logout</a>
          </li>
        </ul>
      </div> 
    </div>
  </nav>
    <router-view></router-view>
  </div>
</template>

<script>
import { useCartStore } from './store/cart';
import { mapState } from 'pinia'
import { useStore } from './store/index'

export default {
  name: 'App',
  setup() {
    useCartStore().loadCart();
    useStore().loadUser()
  },
  computed: {
    ...mapState(useStore, ['isAuthenticated', 'isAuthorized', 'logout']),
  },
  methods: {
    logoutUser() {
      this.logout();
      this.$router.push('/');
    }
  }
};
</script>

<style>
@import './styles/main.css';
</style>
