<template>
  <div class="container mt-5">
    <h2>Category Form</h2>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" v-model="category.name" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="description" class="form-label">Description</label>
        <input type="text" v-model="category.description" class="form-control" />
      </div>
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
</template>

<script>
import categoryService from '../../../services/categoryService';

export default {
  name: 'CategoryUpdate',
  data() {
    return {
      category: {
        name: '',
        description: ''
      }
    };
  },
  methods: {
    async submitForm() {
      try{
        await categoryService.updateCategory(this.category.id, this.category);
        this.$router.push('/admin/categories');
      } catch (error) {
        alert('Failed to submit the form: ' + error.message);
      }
    }
  },
  async created() {
    const id = this.$route.params.id;
    const response = await categoryService.getCategoryById(id);
    this.category = response.data;
  }
};
</script>
