<template>
    <div class="container mt-5">
    <h2>Products</h2>
    <router-link to="/admin/products/add">
    <btn class="btn btn-success">Add</btn>
  </router-link>

    <table class="table">
      <thead class="table-dark">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Name</th>
          <th scope="col">Price</th>
          <th scope="col">Stock</th>
          <th scope="col">Description</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
        <tbody>
          <tr v-for="(product, i) in this.products" :key="i">
            <th scope="row">{{ product.id }}</th>
            <td>{{ product.name }}</td>
            <td>{{ product.price }}</td>
            <td>{{ product.stock }}</td>
            <td>{{ product.description }}</td>
            <td>
              <div class="d-inline me-2"><i class="bi bi-pencil" @click="editProduct(product.id)"></i></div>
              <div class="d-inline ms-2"><i class="bi bi-trash" @click="deleteProduct(product.id)"></i></div>
            </td>
          </tr>
        </tbody>
    </table>

    </div>
</template>


<script>
import productService from '../../../services/productService';

  export default {
    name: 'ProductList',
    data() {
      return {
        products: []
      };
    },
    methods: {
      async deleteProduct(id) {
        await productService.deleteProduct(id)
        location.reload();

      },
      editProduct(id) {
        this.$router.push({ name: "ProductUpdate", params: { id: id } });
      }
    },
    async created() {
      const response = await productService.getAllProducts();
      this.products = response.data.data;
    }
  }
</script>