<template>
    <div class="container mt-5">
      <h2>Admin Dashboard</h2>
      <div class="list-group">
        <router-link class="list-group-item list-group-item-action" to="/admin/products">Manage Products</router-link>
        <router-link class="list-group-item list-group-item-action" to="/admin/categories">Manage Categories</router-link>
        <router-link class="list-group-item list-group-item-action" to="/admin/orders">Manage Orders</router-link>
        <router-link class="list-group-item list-group-item-action" to="/admin/users">Manage Users</router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdminDashboard',
  };
  </script>
  