<template>
    <div class="container mt-5">
      <h1>Login</h1>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="identifier">Username or Email</label>
          <input type="text" v-model="identifier" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" v-model="password" class="form-control" required />
        </div>
        <button type="submit" class="btn btn-primary">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  import { useStore } from '../store';
  
  export default {
    name: 'LoginView',
    data() {
      return {
        identifier: '',
        password: '',
      };
    },
    setup() {
      const store = useStore();
      return { store };
    },
    methods: {
      async login() {
        try {
          await this.store.login({ identifier: this.identifier, password: this.password });
          this.$router.push('/');
        } catch (error) {
          alert('Login failed: ' + error.message);
        }
      },
    },
  };
  </script>
  