<template>
    <div class="container mt-5">
      <div v-if="product">
        <h2>{{ product.name }}</h2>
        <img :src="product.imageUrl" class="img-fluid" alt="Product Image" />
        <p>{{ product.description }}</p>
        <p>${{ product.price }}</p>
        <button class="btn btn-primary" @click="addToCart">Add to Cart</button>
      </div>
    </div>
  </template>
  
  <script>
  import productService from '../services/productService';
  
  export default {
    name: 'ProductDetail',
    data() {
      return {
        product: null,
      };
    },
    methods: {
      async fetchProduct() {
        const id = this.$route.params.id;
        const data = await productService.getProductById(id);
        this.product = data.data
      },
      addToCart() {
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        const existingItem = cartItems.find(item => item.product.id === this.product.id);
        if (existingItem) {
          existingItem.quantity += 1;
        } else {
          cartItems.push({ id: Date.now(), product: this.product, quantity: 1 });
        }
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
      },
    },
    async created() {
      await this.fetchProduct();
    },
  };
  </script>
  